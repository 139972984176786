
export const SAMPLE_INPUT = [
  'Never gonna give you up, never gonna let you down, never gonna run around and desert you',
  '100 pushups, 100 sit-ups, 100 squats, 100km run',
  'Hello darkness my old friend, i come to talk with you again',
];

export const TEAM_TAG = 'MVFC';

export const appDrawerWidth = 320;

export const drawerWidth = 260;

// theme constant
export const gridSpacing = 3;

