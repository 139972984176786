
export const MENU_OPEN = '@customization/MENU_OPEN';

export const MENU_TOGGLE = '@customization/MENU_TOGGLE';

export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
