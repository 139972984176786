import { useSelector } from 'react-redux';
import { createContext, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import './index.css';
// import { GlobalAppContext } from 'context/appContext';

import Routes from 'routes';
import themes from 'themes';
import { config } from 'config';
import NavigationScroll from 'layout/NavigationScroll';
// import { Route, Routes } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import 'assets/scss/style.scss';
import InternetCheck from 'components/InternetCheck';
import AssessmentDetail from 'views/dashboard/AssessmentDetail';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Toaster } from 'react-hot-toast';

export const globalContext = createContext({});

const App = () => {
	const customization = useSelector(({ customization }) => customization);
	const [appData, setAppData] = useState({});

	const handleChangeAppText = (key, value) => {
		setAppData({ ...appData, text: { [key]: value } });
	};

	const handleChangeAppData = (key, value) => {
		setAppData({ ...appData, [key]: value });
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Router basename={config.basename}>
				{/* <GlobalAppContext> */}

				<globalContext.Provider
					value={{ appData, handleChangeAppText, handleChangeAppData }}
				>
					{/* hbrhebjfh */}
					<InternetCheck />
					<StyledEngineProvider injectFirst>
						<ToastContainer
							enableMultiContainer
							containerId={'top-right'}
							autoClose={2000}
							closeButton={true}
							position='top-right'
							theme='light'
							hideProgressBar
						/>
						<ThemeProvider theme={themes(customization)}>
							<CssBaseline />
							<NavigationScroll>
								<Routes />
								<Toaster />
							</NavigationScroll>
						</ThemeProvider>
					</StyledEngineProvider>
				</globalContext.Provider>

				{/* </GlobalAppContext> */}
			</Router>
		</LocalizationProvider>
	);
};

export default App;

// export default Sentry.withProfiler(App);
