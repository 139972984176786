import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon for the close button
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	IconButton,
	Modal,
	Stack,
	Typography,
} from '@mui/material';
import { fetchSessions } from 'config/openAI';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import {
	checkSessionsExitOrNot,
	getTrainingDrills,
	uploadSession,
} from 'global/redux/player/request';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';

const GeneratedTrainingSessions = ({ startDate, endDate, days, inputText }) => {
	const [open, setOpen] = useState(false);
	const [slots, setSlots] = useState([]); // Initialize with an empty array
	const [loading, setLoading] = useState(false); // Set initial loading state to false
	const currentTeam = useSelector(({ assessment }) => assessment.currentTeam);
	const [saving, setSaving] = useState(false);
	const [generatePressed, setGeneratePressed] = useState();
	const [selectedSlots, setSelectedSlots] = useState([]);

	const handleCheckboxChange = (slot) => {
		setSelectedSlots((prevSelectedSlots) => {
			if (prevSelectedSlots.some((s) => s.date === slot.date)) {
				// Deselecting the slot
				return prevSelectedSlots.filter((s) => s.date !== slot.date);
			} else {
				// Selecting the slot
				return [...prevSelectedSlots, slot];
			}
		});
	};

	console.log('selectedSlots', selectedSlots);

	const isMatchingSlot = (arr1, arr2) => {
		for (const item of arr1) {
			const timeToPlayDate = new Date(item.timeToPlay.seconds * 1000);

			for (const dateString of arr2) {
				const date = new Date(dateString);
				if (timeToPlayDate.getTime() === date.getTime()) {
					console.log(item);
					return date; // Return the matched timeToPlay date
				}
			}
		}
		return null; // Return null if no match is found
	};

	const handleOpenOrNot = async () => {
		setGeneratePressed(true);
		if (startDate != null && endDate != null && days != null) {
			if (slots?.length == 0) {
				toast.error(
					'No days in the selected range. select a different date range'
				);
				setGeneratePressed(false);

				return;
			}

			try {
				const hasSessions = await checkSessionsExitOrNot({
					teamId: currentTeam.club,
					startDateTime: startDate, // Ensure this is in a format that can be parsed
					slots: slots?.map((_slot) =>
						dayjs(_slot.date, 'DD/MM/YYYY h:mm A').toDate().toString()
					), // Ensure correct conversion
				});
				console.log(
					'hasSessions',
					hasSessions.map((itemm) => {
						return itemm.createdAt != null && itemm;
					})
				);

				// if (hasSessions?.length > 0) {
				const result = isMatchingSlot(
					hasSessions,
					slots?.map((_slot) =>
						dayjs(_slot.date, 'DD/MM/YYYY h:mm A').toDate().toString()
					)
				);
				if (result != null) {
					toast.error(
						`You already have training session on " ${dayjs(result).format(
							'dddd MMM DD, YYYY hh:mm a'
						)}"`
					);
				} else {
					setOpen(true);
					fetchData();
				}
				// } else {
				// 	setOpen(true);
				// 	fetchData();
				// }
			} catch (error) {
				console.log('hasSessions', error);
				toast.error('zerror checking session : hasSessions');
			}
		} else {
			toast.error('Select days, start date & end date');
		}
		setGeneratePressed(false);
	};

	const fetchData = async () => {
		console.log('fetchData');
		if (!slots || slots.length === 0) return;
		try {
			setLoading(true);

			// for production
			// #############################
			const sessionIds = await fetchSessions(inputText);
			const drills_ = await getTrainingDrills([...sessionIds]);

			// for test
			// #############################
			// const drills_ = await getTrainingDrills(['E2A0DF40', '26F056EA']);

			if (drills_?.length == 0) {
				toast.error('No drills found in the database');
			}
			// console.log('drills_', drills_);

			console.log('before slotsWithData', slots);
			const slotsWithData = slots.map((slot, index) => ({
				...slot,
				stockTime: startDate,
				posts: drills_,
			}));
			console.log('slotsWithData', slotsWithData);

			setSlots(slotsWithData);
		} catch (error) {
			toast.error('Error generating sessions');

			console.error('Error fetching data:', error);
		}
		setLoading(false);
	};

	const sendSession = async () => {
		setSaving(true);
		if (selectedSlots?.posts?.length != 0) {
			try {
				const hasSessions = await checkSessionsExitOrNot({
					teamId: currentTeam.club,
					startDateTime: startDate,
					slots: selectedSlots?.map((_slot) =>
						dayjs(_slot.date, 'DD/MM/YYYY h:mm A').toDate()
					),
				});
				console.log('hasSessions', hasSessions);

				const result = isMatchingSlot(
					hasSessions,
					selectedSlots?.map((_slot) =>
						dayjs(_slot.date, 'DD/MM/YYYY h:mm A').toDate().toString()
					)
				);
				console.log('isMatchingSlot', result);

				if (result != null) {
					toast.error(
						`You already have training sessions on " ${dayjs(result).format(
							'dddd MMM DD, YYYY hh:mm a'
						)}"`
					);
				} else {
					const arrOfSessions = selectedSlots.map((item) =>
						uploadTrainingSession({
							sessions: item.posts,
							timeToPlay: item.date,
							teamID: currentTeam.club,
						})
					);
					console.log(arrOfSessions);

					await uploadSession(arrOfSessions)
						.then((res) => {
							toast.success('Sessions saved');
							// console.log(res);
						})
						.catch((error) => {
							toast.error('Error saving sessions');

							console.log(error);
						});

					console.log(arrOfSessions);
				}
			} catch (error) {
				console.log('hasSessions', error);
				toast.error('Error saving sessions: hasSessions');
			}
		}
		setSaving(false);
	};

	function getDatesInRange() {
		if (!startDate || !endDate || !days || days.length === 0) return []; // Check for required inputs

		const dayMapping = {
			Sunday: 0,
			Monday: 1,
			Tuesday: 2,
			Wednesday: 3,
			Thursday: 4,
			Friday: 5,
			Saturday: 6,
		};

		let result = [];
		let current = startDate.clone(); // Clone the startDate to avoid mutating the original object

		while (current.isBefore(endDate) || current.isSame(endDate, 'day')) {
			let currentDay = current.day();
			if (
				days.includes(
					Object.keys(dayMapping).find((key) => dayMapping[key] === currentDay)
				)
			) {
				result.push({
					date: current.format('DD/MM/YYYY hh:mm a'), // Format date as required
					day: Object.keys(dayMapping).find(
						(key) => dayMapping[key] === currentDay
					),
				});
			}
			current = current.add(1, 'day'); // Move to the next day
		}

		return result;
	}

	const uploadTrainingSession = ({ sessions, teamID, timeToPlay }) => {
		const parsedDate = dayjs(timeToPlay, 'DD/MM/YYYY hh:mm a');
		const dateObject = parsedDate.toDate();
		return {
			teamIDs: `empty-${teamID}`,
			teamBCustom: null,
			[teamID]: null,
			teamID: null,
			noteVideo: [
				...sessions.map((item) => {
					return { url: item.youtube_link, title: item.name_of_session };
				}),
			],
			teams: [teamID, 'empty'],
			rating: null,
			status: 'waiting',
			stadium: null,
			timeToPlay: Timestamp.fromDate(dateObject),
			event: '4kviwcHueMOiDWK1sDDC',
			drillModels: [...sessions],
			notes: null,
			empty: null,
		};
	};

	useEffect(() => {
		if (startDate && endDate && days) {
			const __slots = getDatesInRange();
			setSlots(__slots);
		}
	}, [startDate, endDate, days]);

	return (
		<>
			<LoadingButton
				fullWidth
				disableElevation
				loading={generatePressed}
				variant='contained'
				sx={{ color: 'white' }}
				onClick={() => {
					handleOpenOrNot();
				}}
			>
				Generate
			</LoadingButton>
			<Modal
				open={open}
				// onClose={() => {
				// 	setOpen(false);
				// }}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '90vw',
						height: '80vh',
						bgcolor: 'background.paper',
						borderRadius: 2,
						p: 3,
						overflowY: 'auto',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Typography variant='h6' id='modal-modal-title'>
							Training Sessions
						</Typography>
						<IconButton
							onClick={() => {
								setOpen(false);
							}}
							aria-label='close'
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Box sx={{ flex: 1, overflowY: 'auto' }}>
						{loading ? (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									height: '100%',
								}}
							>
								<CircularProgress />
							</Box>
						) : (
							<Grid container spacing={4} wrap='wrap'>
								{slots?.map((slot, index) => (
									<Grid item xs={12} sm={6} md={4} key={index}>
										<Box
											sx={{
												border: '1px solid #ccc',
												borderRadius: '8px',
												p: 2,
												width: '100%', // Ensure box takes full width of grid item
											}}
										>
											{/* Date Heading */}
											{/* <Typography variant='h6'>
												{slot?.date} - {slot?.day}
											</Typography> */}
											<FormControlLabel
												control={
													<Checkbox
														checked={selectedSlots.some(
															(s) => s.date === slot.date
														)}
														onChange={() => handleCheckboxChange(slot)}
													/>
												}
												label={`${slot.date} - ${slot.day}`}
											/>
											{/* Map each post for this date */}
											<Stack spacing={2}>
												{slot?.posts?.map((item, itemIndex) => (
													<Box
														key={itemIndex}
														sx={{
															border: '1px solid #ccc',
															borderRadius: '8px',
															p: 2,
														}}
													>
														{/* <video width='100%' height='auto' controls>
															<source
																src={item.youtube_link}
																// type='video/mp4'
															/>
														</video> */}

														<ReactPlayer
															width={'100%'}
															// height='200px'
															controls={true}
															url={item.youtube_link}
														/>

														<Typography variant='h6'>
															{item?.duration_of_training_drill}
														</Typography>
														<Typography variant='h6'>
															{item?.name_of_session}
														</Typography>
														<Typography variant='body2'>
															{item?.ability_suitable_for_and_comments}
														</Typography>
													</Box>
												))}
											</Stack>
										</Box>
									</Grid>
								))}
							</Grid>
						)}
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
						<LoadingButton
							loading={saving}
							disabled={loading || slots[0]?.posts?.length == 0}
							variant='contained'
							onClick={() => {
								// Handle submit action here
								sendSession();
							}}
						>
							Submit
						</LoadingButton>
					</Box>
				</Box>
			</Modal>
		</>
	);
};

export default GeneratedTrainingSessions;
