import { toast } from 'react-toastify';
// import prettyMilliseconds from 'pretty-ms';
// import humanizeDuration from 'humanize-duration';

const showNoti = (type, message, position = 'top-right') =>
	toast?.[type](message, { containerId: position });

// const convertDate = (time) => {
//   //time should be server timestamp seconds only
//   const dateInMillis = time * 1000;
//   const date = new Date(dateInMillis);
//   let myDate = date.toLocaleDateString();
//   const myTime = date.toLocaleTimeString();
//   myDate = myDate.replaceAll('/', '-');
//   const newDateFormat = myDate.split('-');
//   return `${newDateFormat[1]}-${newDateFormat[0]}-${newDateFormat[2]} ${myTime}`;
// };

var months = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

const convertDate = (timeInSeconds) => {
	const date = new Date(timeInSeconds * 1000);
	const currentTime = new Date();
	const timeDifference = Math.abs(currentTime - date);
	const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
	const hours = Math.floor(
		(timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
	);
	const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

	if (days > 1) {
		let date = new Date(timeInSeconds * 1000);
		let year = date.getFullYear();
		let month = date.getMonth();
		let day = ('0' + date.getDate()).slice(-2);
		let hours = date.getHours();
		let minutes = ('0' + date.getMinutes()).slice(-2);
		let meridiem = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12 === 0 ? 12 : hours % 12;
		let dateTime = `${months[month]} ${day}, ${year} at ${hours}:${minutes} ${meridiem}`;
		return dateTime;
	} else if (days === 0) {
		if (hours == 0 && minutes == 0) {
			return `now`;
		} else if (hours == 0 && minutes != 0) {
			return `${minutes}m ago`;
		} else if (hours != 0 && minutes == 0) return `${hours}h ago`;
		else {
			return `${hours}h ${minutes}m ago`;
		}
	} else {
		return `${days}d ${hours}h ${minutes}m ago`;
	}
};

const getCurrentDate = (type) => {
	const today = new Date();
	const yyyy = today.getFullYear();
	let mm = today.getMonth() + 1;
	let dd = today.getDate();
	const time = today.toLocaleString('en-US', {
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		hour12: true,
	});

	if (dd < 10) {
		dd = '0' + dd;
	}
	if (mm < 10) {
		mm = '0' + mm;
	}

	if (type === 'seconds') {
		return Math.round(Date.now() / 1000);
	} else {
		return `${mm}-${dd}-${yyyy} ${time}`;
	}
};

const toCappitalize = (word) => {
	return word?.charAt(0).toUpperCase() + word?.slice(1);
};

export { convertDate, getCurrentDate, showNoti, toCappitalize };
