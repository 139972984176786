import React, { useEffect } from 'react';
import {
	Chart as ChartJS,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip,
	Legend,
} from 'chart.js';

import { Radar } from 'react-chartjs-2';
import { toCappitalize } from 'utils/helper';
import { Box } from '@mui/material';
ChartJS.register(
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip,
	Legend
);

const RadarChart = ({ playerSkillRating, skillRatingTable }) => {
	// const newSkills = [
	//   { skill: 'Striking the Ball', score: playerSkillRating['Striking the Ball'] },
	//   { skill: 'Receiving (Awareness)', score: playerSkillRating['Receiving (Awareness)'] },
	//   { skill: 'Keep the ball (Evasion)', score: playerSkillRating['Keep the ball (Evasion)'] },
	//   { skill: '1v1 Defending', score: playerSkillRating['1v1 Defending'] },
	//   { skill: '1v1 Attacking', score: playerSkillRating['1v1 Attacking'] },
	//   { skill: 'Running with the Ball', score: playerSkillRating['Running with the Ball'] },
	//   { skill: 'Proactive Defending', score: playerSkillRating['Proactive Defending'] },
	// ];

	function getMaxNumberOfEntries(obj) {
		let maxKeysCount = 0;

		for (const key in obj) {
			const nestedObject = obj[key];
			const nestedKeysCount = Object.keys(nestedObject).length;
			if (nestedKeysCount > maxKeysCount) {
				maxKeysCount = nestedKeysCount;
			}
		}

		return maxKeysCount + 1;
	}

	function matchKeys(json1, json2) {
		const matchedKeys = {};

		// Find matching keys and store their key-value pairs
		for (const key in json1) {
			if (json2.hasOwnProperty(key)) {
				matchedKeys[key] = json1[key];
			}
		}

		// Sort the keys alphabetically
		const sortedKeys = Object.keys(matchedKeys).sort();

		// Create a new object with sorted keys
		const sortedMatchedKeys = {};
		sortedKeys.forEach((key) => {
			sortedMatchedKeys[key] = matchedKeys[key];
		});

		return sortedMatchedKeys;
	}

	function convertSkillsData() {
		let result = [];
		for (const [skill, score] of Object.entries(
			matchKeys(playerSkillRating, skillRatingTable)
		)) {
			result.push({ skill: skill, score: score });
		}
		return result;
	}

	function getMax(inputJson) {
		const _oneLevelJson = {};
		let highestValue = parseFloat(0);
		for (const key1 in inputJson) {
			const innerData = inputJson[key1];
			for (const key2 in innerData) {
				_oneLevelJson[key2] = innerData[key2];
				if (innerData[key2] > highestValue) {
					highestValue = parseFloat(innerData[key2]) ;
				}
			}
			return parseInt( highestValue + highestValue / 4);
		}
		// console.log(highestValue);
	}	

	// const formattedSkillsData = [...Object.keys(skillRatingTable).map(item => ({skill: item, score: 0})), ...convertSkillsData()];

	const formattedSkillsData = [...Object?.keys(skillRatingTable)?.map(item => ({skill: item, score: 0})), ...convertSkillsData()]?.reduce((acc, current) => {
		const existingItemIndex = acc?.findIndex(item => item?.skill === current?.skill);
		if (existingItemIndex !== -1) {
			if (current?.score > acc[existingItemIndex]?.score) {
				acc[existingItemIndex] = current;
			}
		} else {
			acc?.push(current);
		}
		return acc;
	}, []);


	// console.log(Object.keys(skillRatingTable).map(item => ({skill: item, score: 0})))

	const options = {
		scale: {
			ticks: {
				stepSize: parseInt( getMax(skillRatingTable)/ getMaxNumberOfEntries(skillRatingTable)),
			},
			r: {
				max: 100,
				min: 0,
			},
		},
	};
	const data = {
		labels: formattedSkillsData?.map((item) => toCappitalize(item?.skill)),
		datasets: [
			{
				label: 'Skill score',
				data: formattedSkillsData?.map((item) => item.score),
				backgroundColor: 'rgb(255,113,41,0.2)',
				borderColor: 'rgb(255,113,41, 0.5)',
				borderWidth: 1,
			},
		],
	};

	// useEffect(() => {
	// 	if(Object.keys(playerSkillRating)?.length < 1) {

	// 	}

	// }, [skillRatingTable])

	// console.log(JSON.stringify(skillRatingTable));
	// console.log(JSON.stringify(playerSkillRating));
	// console.log(getMax(skillRatingTable));

	return (
		<div style={{ width: '400px', height: '400px' }}>
			<Radar data={data} options={options} />
		</div>
	);
};

export default RadarChart;
