import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from '@mui/material';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

const AttendanceTable = ({ attendedEvents, events }) => {
  // Function to group events by name and attended status
  const groupEvents = () => {
    const attendedGroup = {};
    const unattendedGroup = {};

    events.forEach((event) => {
      const { eventName, eventID } = event;
      const attended = attendedEvents.find((a) => a.gameID === eventID);

      if (attended && attended.gameID === eventID) {
        if (!attendedGroup[eventName]) {
          attendedGroup[eventName] = [];
        }
        attendedGroup[eventName].push(event);
      } else {
        if (!unattendedGroup[eventName]) {
          unattendedGroup[eventName] = [];
        }
        unattendedGroup[eventName].push(event);
      }
    });

    return { attendedGroup, unattendedGroup };
  };

  const { attendedGroup, unattendedGroup } = groupEvents();

  return (
    <Table sx={{ border: '1px solid #000', maxWidth: '700px', width: '100%' }}>
      <TableHead>
        <TableRow>
          <TableCell align='center' sx={{ border: '1px solid #000' }}>
            {`Sessions (${events?.length})`}
          </TableCell>
          <TableCell align='center' sx={{ border: '1px solid #000' }}>
            {`Attended (${attendedEvents?.length})`}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(attendedGroup).map(([eventName, events]) => (
          <TableRow key={`${eventName}-attended`} sx={{ border: '1px solid #000' }}>
            <TableCell align='center' sx={{ border: '1px solid #000' }}>{`${eventName} (${events.length})`}</TableCell>
            <TableCell align='center' sx={{ border: '1px solid #000' }}>
              <Box
                sx={{
                  backgroundColor: 'rgba(76, 175, 80, 0.2)', // Green background for attended events
                  borderRadius: '4px',
                  padding: '8px',
                  margin: '2px',
                }}
              >
                <DoneOutlineIcon sx={{ color: 'green' }} />
              </Box>
            </TableCell>
          </TableRow>
        ))}
        {Object.entries(unattendedGroup).map(([eventName, events]) => (
          <TableRow key={`${eventName}-unattended`} sx={{ border: '1px solid #000' }}>
            <TableCell align='center' sx={{ border: '1px solid #000' }}>{`${eventName} (${events.length})`}</TableCell>
            <TableCell align='center' sx={{ border: '1px solid #000' }}>
              <Box
                sx={{
                  backgroundColor: 'rgba(244, 67, 54, 0.2)', // Red background for unattended events
                  borderRadius: '4px',
                  padding: '8px',
                  margin: '2px',
                }}
              >
                <DoNotDisturbIcon sx={{ color: 'red' }} />
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default AttendanceTable;
