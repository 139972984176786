import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AssessmentDetail from 'views/dashboard/AssessmentDetail';
import PlayerDetail from 'views/dashboard/PlayerDetail';

// dashboard routing
const AssessmentTable = Loadable(
	lazy(() => import('views/dashboard/AssessmentTable'))
);
// const AssessmentDetail = Loadable(lazy(
//   () => import('views/dashboard/AssessmentDetail')));
// const PlayerDetail = Loadable(
// 	lazy(() => import('views/dashboard/PlayerDetail'))
// );
 
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
	path: '/',
	element: <MainLayout />,
	children: [
		{
			path: 'dashboard',
			element: <AssessmentTable />,
		},
		{
			path: 'dashboard/assessment',
			element: <AssessmentTable />,
		},
		{
			path: 'dashboard/assessment/:assessmentId/:eventId',
			element: <AssessmentDetail />,
		},
		{
			path: 'dashboard/assessment/:assessmentId/:eventId/:playerId',
			element: <PlayerDetail />,
		},
		// {
		// 	path: 'dashboard/assessment/:assessmentId/:eventId/:playerId/:playerId',
		// 	element: <AssessmentDetail />,
		// },
	],
};

export default MainRoutes;
