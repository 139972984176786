import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
	Divider,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box, maxWidth } from '@mui/system';
import Loader from 'components/Loadable/Loader';
import RadarChart from 'components/RadarChart';
import dayjs from 'dayjs';
import {
	getSkillRatingTable,
	getSkillRatings,
} from 'global/redux/player/request';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	getPlayerReflection,
	updatePlayerSkills,
} from 'global/redux/player/thunk';

const useStyles = makeStyles(() => ({
	root: {},
	0: {
		backgroundColor: '#FCEBCD',
	},
	1: {
		backgroundColor: '#FBD89B',
	},
	2: {
		backgroundColor: '#CDFCEB',
	},
	3: {
		backgroundColor: '#FCD3CD',
	},
	4: {
		backgroundColor: '#FBA89B',
	},
	5: {
		backgroundColor: '#91e7eb',
	},
	6: {
		backgroundColor: '#c191eb',
	},
	7: {
		backgroundColor: '#e2eb91',
	},
	8: {
		backgroundColor: '#eb9191',
	},
	hover: {
		'&:hover': {
			backgroundColor: '#faeee8',
			transition: 'transform 0.3s ease-in-out',
		},
	},
	tableBorder: {
		borderWidth: 1,
		// borderRightWidth: 1,
		// borderBottomWidth: 1,
		borderColor: 'black',
		borderStyle: 'solid',
		cursor: 'pointer', // Change cursor to pointer
		transition: 'transform 0.3s ease-in-out',
	},
	bgTransparent: {
		backgroundColor: 'transparent',
		border: 'none',
	},
}));

export function SkillRatingTable() {
	const classes = useStyles();
	const params = useParams();
	const dispatch = useDispatch();
	const { eventId, playerId, assessmentId } = params;
	const {
		isLoading,
		selfImprove,
		selfImproveLoading,
		reflection,
		psychology,
		playerSkillRating,
	} = useSelector(({ player }) => ({
		isLoading: player.isLoading,
		selfImprove: player.selfImprove,
		selfImproveLoading: player.selfImproveLoading,
		reflection: player.reflection,
		psychology: player.psychology,
		playerSkillRating: player.playerSkillRating,
	}));
	const { attendendEvents, isLoadingAttendedEvents } = useSelector(
		({ player }) => ({
			attendendEvents: player?.attendendEvents,
			isLoadingAttendedEvents: player?.isLoadingAttendedEvents,
		})
	);
	const { playerInAssessment } = useSelector(({ assessment }) => assessment);
	const { assessment } = useSelector(({ assessment }) => ({
		isLoading: assessment.isLoading,
		assessment: assessment.assessment?.find((item) => item?.id == assessmentId),
	}));
	const playerInfo = playerInAssessment.filter(
		(item) => item?.userID === playerId
	)[0];
	const { userInfo, clubList, totalTeam } = useSelector(({ auth }) => ({
		userInfo: auth.userInfo,
		clubList: auth.clubList,
		totalTeam: auth.totalTeam,
	}));
	const [skillRatingTable, setSkillRatingTable] = useState();
	const [sessionRating, setSessionRating] = useState();
	const currentDate = new Date();
	const [dateRange, setDateRange] = useState({
		start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
		end: new Date(currentDate),
	});
	const [loadingSkillRating, setLoadingSkillRating] = useState(null);

	const handleGetSkillRatingTable = async () => {
		await getSkillRatingTable(assessment.teamID)
			.then((res) => {
				setSkillRatingTable(res);
			})
			.catch((err) => {
				console.log(err);
				setSkillRatingTable('error');
			});
	};

	function convertSkillRating(data) {
		const result = [];

		data?.forEach((session) => {
			const sessionRating = session?.sessionRating;

			Object.values(sessionRating).forEach((category) => {
				if (typeof category == 'object') {
					result.push(category);
				}
			});
		});

		return result;
	}

	function countSkillRatingCount(name) {
		let count = 0;
		sessionRating?.forEach((obj) => {
			const keys = Object.keys(obj);
			if (keys.length > 0 && keys[0] === name) {
				count++;
			}
		});

		return count > 0 && `(${count})`;
	}

	const getSkillRating = async () => {
		setLoadingSkillRating(true);
		await getSkillRatings(playerId, {
			start: dateRange?.start,
			end: dateRange?.end,
		})
			.then((res) => {
				setSessionRating(convertSkillRating(res));
			})
			.catch((err) => {
				console.log(err);
			});
		setLoadingSkillRating(false);
	};

	function getObjectWithMaxKeys(obj) {
		let maxKeysCount = 0;
		let maxKeysObject = null;

		for (const key in obj) {
			const nestedObject = obj[key];
			const nestedKeysCount = Object.keys(nestedObject).length;
			if (nestedKeysCount > maxKeysCount) {
				maxKeysCount = nestedKeysCount;
				maxKeysObject = nestedObject;
			}
		}

		return maxKeysObject;
	}
	const handleUpdatePlayerSkill = (skill, value) => {
		console.log(skill, value);
		
		dispatch(
			updatePlayerSkills({
				skill: { ...playerSkillRating, [skill]: value },
				assessmentId: assessmentId,
				playerAssessmentId: playerInfo?.id,
			})
		);

		dispatch(
			getPlayerReflection({
				assessmentId,
				playerAssessmentId: playerInfo?.id,
			})
		);
	};

	useEffect(() => {
		if (!sessionRating) {
			getSkillRating();
		}
	}, [eventId, userInfo]);

	// console.log(
	// 	skillRatingTable && Object.entries(skillRatingTable['1v1 Attacking'])
	// );
	// console.log(getObjectWithMaxKeys(skillRatingTable));
	// console.log(playerSkillRating);
	// console.log(skillRatingTable);

	useEffect(() => {
		
		
	}, [skillRatingTable])

	useEffect(() => {
		if (assessment) {
			handleGetSkillRatingTable();
		}
	}, [assessment]);

	return (
		<>
			{skillRatingTable ? (
				<>
					{skillRatingTable == 'error' ? (
						<p>Something Went Wrong. Kindly refresh or contact support.</p>
					) : (
						<>
							{Object.keys(skillRatingTable)
								.filter((key) => key.includes('sessionRating'))
								?.map((value, i) => (
									<Box sx={{ p: '0 30px' }}>
										<Divider
											sx={{
												'&::before, &::after': {
													borderColor: 'primary.dark',
												},
											}}
										>
											<Typography variant='h2'>Skills</Typography>
										</Divider>
										<Typography
											variant='subtitle1'
											textAlign={'center'}
											paddingY={2}
										>
											*Click on the table &quot;cell&quot; to update the
											rating.
										</Typography>
										<div className='dateRangeContainer'>
											{/* <div style={{ maxWidth: '400px' }}> */}
												<RadarChart
													playerSkillRating={playerSkillRating}
													skillRatingTable={skillRatingTable[value]}
												/>
											{/* </div> */}
											<div>
												<div className='dateRangeSelector'>
													<DatePicker
														label='Start Date'
														value={dayjs(new Date(dateRange?.start))}
														onChange={(range) =>
															setDateRange({
																...dateRange,
																start: new Date(range),
															})
														}
													/>
													<DatePicker
														label='End Date'
														value={dayjs(new Date(dateRange?.end))}
														onChange={(range) =>
															setDateRange({
																...dateRange,
																end: new Date(range),
															})
														}
													/>
												</div>
												<LoadingButton
												sx={{marginTop: '10px'}}
												fullWidth
													onClick={() => getSkillRating()}
													loading={loadingSkillRating}
													size='large'	
													variant='outlined'
												>
													Select
												</LoadingButton>
											</div>
										</div>
										<Stack direction={'column-reverse'}>
											<Stack
												direction='row'
												justifyContent='space-between'
												sx={{ p: 3 }}
											>
												<Box
													sx={{
														width: '100%',
													}}
												>
													<TableContainer
														component={Paper}
														sx={{ borderRadius: 0 }}
													>
														<Table
															sx={{
																tableLayout: 'auto',
																borderCollapse: 'collapse',
																border: '1px solid black',
																borderBottom: '1.5px solid black',
															}}
														>
															<TableHead>
																<TableRow>
																	<TableCell
																		align='center'
																		className={classes.tableBorder}
																	>
																		TOPIC
																	</TableCell>
																	{Object.keys(
																		getObjectWithMaxKeys(
																			skillRatingTable[value]
																		)
																	)?.map((res, i) => (
																		<TableCell
																			align='center'
																			className={`${classes.tableBorder}`}
																		>
																			Level {i + 1}
																		</TableCell>
																	))}
																</TableRow>
															</TableHead>
															<TableBody>
																{Object.keys(skillRatingTable[value])
																	?.sort()
																	?.map((skill, skillIndex) => (
																		<TableRow>
																			<TableCell
																				align='center'
																				className={`${classes.tableBorder}`}
																			>
																				<b>{skill}</b>
																			</TableCell>
																			{Object.entries(
																				skillRatingTable[value][skill]
																			)
																				?.sort((a, b) => a[1] - b[1])
																				?.map((subSkill, subSkillIndex) => (
																					<TableCell
																						align='center'
																						className={`${
																							classes.tableBorder
																						} ${classes.hover} ${
																							playerSkillRating[skill] ==
																								subSkill[1] &&
																							classes[skillIndex]
																						}`}
																						onClick={() => {
																							handleUpdatePlayerSkill(
																								skill,
																								parseFloat(subSkill[1])
																							);
																						}}
																					>
																						{subSkill[0]}
																						<div className='skillRatingCount'>
																							{countSkillRatingCount(
																								subSkill[0]
																							)}
																						</div>
																					</TableCell>
																				))}
																		</TableRow>
																	))}
															</TableBody>
														</Table>
													</TableContainer>
													{/*eslint-enable*/}
												</Box>
											</Stack>
										</Stack>
									</Box>
								))}
						</>
					)}
				</>
			) : (
				<Loader />
			)}
		</>
	);
}
